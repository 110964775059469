import React, { useState, useEffect } from "react";
import {
  useGetIdentity,
  ReferenceInput,
  SelectInput,
  Loading,
  LinearProgress,
  Datagrid,
  ReferenceField,
  TextInput,
  AutocompleteInput,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
} from "react-admin";
import { useFormState } from "react-final-form";

const SelectVote = (props) => {
  const [loading, setLoading] = useState(true);
  const [choices, setChoices] = useState([]);
  const { values } = useFormState();
  useEffect(() => {
    
    if (values.poll_id && props.choices.length > 0) {
      const answers = props.choices[0].answers.map((ans, i) => {
        return { id: ans.option, name: ans.option };
      });
      setChoices(answers);
      setLoading(false);
    }
  }, [props, values.poll_id]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <SelectInput
      source='answer'
      label='Ответ'
      choices={choices}
      emptyValue='null'
      translateChoice={false}
    />
  );
};

export const PollResList = ({ permissions, ...props }) => {
  return (
    <List {...props} sort={{ field: "poll_id", order: "ASC" }}>
      <Datagrid rowClick='edit'>
        <ReferenceField
          source='user_id'
          reference='users'
          label='Проголосовавший'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='poll_id' reference='polls' label='Опрос'>
          <TextField source='question' />
        </ReferenceField>
        <TextField source='answer' label='Ответ' />
        <TextField source='createdby' label='Заполняющий' />
        {permissions === "admin" && <EditButton label='' />}
      </Datagrid>
    </List>
  );
};

export function PollResCreate({ permissions, ...props }) {
  const { identity, loading: userloading } = useGetIdentity();
  if (userloading) {
    return <Loading />;
  }
  return (
    <Create title='Добавить голос' {...props}>
      <SimpleForm>
        <ReferenceInput
          label='Голосующий'
          source='user_id'
          reference='users'
          sort={{ field: "name", order: "ASC" }}
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <AutocompleteInput
            optionText='name'
            emptyValue='null'
            emptyText='Отсутствует'
            allowEmpty={true}
          />
        </ReferenceInput>
        <ReferenceInput
          label='Опрос'
          source='poll_id'
          reference='polls'
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <AutocompleteInput
            optionText='question'
            emptyValue='null'
            emptyText='Отсутствует'
            allowEmpty={true}
          />
        </ReferenceInput>
        <ReferenceInput
          label='Опрос'
          source='answers'
          reference='polls'
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <SelectVote {...props} />
        </ReferenceInput>
        <TextInput
          source='createdby'
          label='Заполняющий'
          initialValue={identity.email}
          disabled
          type='text'
        />
      </SimpleForm>
    </Create>
  );
}

export function PollResEdit({ permissions, ...props }) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput
          label='Голосующий'
          source='user_id'
          reference='users'
          sort={{ field: "name", order: "ASC" }}
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <AutocompleteInput
            optionText='name'
            emptyValue='null'
            emptyText='Отсутствует'
            allowEmpty={true}
          />
        </ReferenceInput>
        <ReferenceInput
          label='Опрос'
          source='poll_id'
          reference='polls'
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <AutocompleteInput
            optionText='question'
            emptyValue='null'
            emptyText='Отсутствует'
            allowEmpty={true}
          />
        </ReferenceInput>
        <ReferenceInput
          label='Опрос'
          source='answers'
          reference='polls'
          emptyValue={null}
          allowEmpty
          emptyText='-Отсутствует-'>
          <SelectVote />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
